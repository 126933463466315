import {ACCOUNT_TYPE} from "./core/config/account";
export const Menu = [
    // Danh sách
    {
        "title": "Quản lý lớp",
        "icon": "fa-list-ul",
        "url": "/classrooms",
        'account' : [ACCOUNT_TYPE.TEACHER],
    },
    {
        "title": "Thống kê",
        "icon": "fa-line-chart",
        "url": "/home",
        'account' : [ACCOUNT_TYPE.TEACHER],
    },
    {
        "title": "Thu nhập",
        "icon": "fa-dollar-sign",
        "url": "/salary",
        'account' : [ACCOUNT_TYPE.TEACHER],
    },
    {
        "title": "Thông báo",
        "icon": "fa-comment",
        "url": "/notification",
        'account' : [ACCOUNT_TYPE.TEACHER],
    },
    {
        "title": "Học tập",
        "icon": "fa-info-square",
        'account' : [ACCOUNT_TYPE.CANDIDATE_TEACHER , ACCOUNT_TYPE.TEACHER],
        "child": [
            {
                "url": "/Redirectquantumleap",
                "title": "Giới thiệu Quantum Leap",
            },
            {
                "url": "/listSchedule",
                "title": "Danh sách khóa học",
            },
        ]
    }

];